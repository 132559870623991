import { init as initApm } from '@elastic/apm-rum'

export default {
  init: () => {
    initApm({
      serviceName: 'seminovos-portal-frontend-totem-shopping',
      serverUrl: 'https://apm.carmi.com.br:443',
      environment: process.env.REACT_APP_APM_ENV
    })
  }
}
