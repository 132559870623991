import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import apmShopping from './config/apm-shopping';

if (process.env.REACT_APP_START_APM === 'enabled'){
    apmShopping.init()
}

ReactDOM.render(<App />, document.getElementById('root'));
